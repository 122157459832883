import React, {useEffect, useState} from 'react';
import {Row, Col, ContentContainer, Heading, SectionPage, Button, Textarea} from '@snsw-gel/react';
import PropTypes from 'prop-types';
import apiServices from '../../services/api-services';
import {CustomerField, ErrorAlert} from '../FeedbackComponent/FeedbackComponent.styled';
import {useNavigate} from 'react-router';

const FeedbackComponent = (props) => {
    const params = new URLSearchParams(window.location.search);
    const rating = (params.get('rating') === 'POSITIVE') ? 5 : 1;
    const notificationCode = params.get('notification_code');
    const translationType = params.get('translationType');
    const language = params.get('language');
    const initialComment = notificationCode + ' ' + translationType + ' ' + language;
    const [comment, setComment] = useState('');
    const [errorAlert, setErrorAlert] = useState(false);
    const [inputError, SetInputError] = useState({comment: {hasError: false, errorMessage: ''}});
    const navigate = useNavigate();

    const config = {
        'applicationName': `${process.env.REACT_APP_HOME_URL}`,
        'sourceApplication': `${process.env.REACT_APP_SOURCEAPPLICATION}`,
    };

    useEffect(() => {
        console.log(`send feedback when load page with initial comment: ${initialComment}`);
        console.log(`rating: ${rating}`);

        apiServices.postFeedback(rating, config.applicationName, config.sourceApplication,initialComment)
            .then((response) => {
                console.log('successful');
            })
            .catch((e) => {
                console.log('failure');
            });// eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const handleChange = (event) => {
        console.log(event.target.value);
        if (event.target.value !== '') {
            setComment(event.target.value);
            SetInputError(prevState => ({
                ...prevState,
                comment: {hasError: false, errorMessage: ''}
            }));
        }
    };
    const feedbackSend = () => {
        if (comment.length === 0) {
            SetInputError(prevState => ({
                ...prevState,
                comment: {hasError: true, errorMessage: 'Provide a description'}
            }));
            return;
        }
        const submitComment = `${initialComment} | ${comment}`;
        console.log(submitComment);
        apiServices.postFeedback(rating, config.applicationName, config.sourceApplication,submitComment)
            .then((response) => {
                navigate('/success');
            })
            .catch((e) => {
                setErrorAlert(true);
            });
    };
    return (
        <ContentContainer>
            <SectionPage>
                {errorAlert &&
                    <ErrorAlert headingLevel='h3' variant= { 'error' } title='Something went wrong' role='alert'>
                        <div data-testid='error-alert'>
                            <span>We were unable to submit your feedback, please try again.</span>
                        </div>
                    </ErrorAlert>
                }
                <Row justifyContent={ 'center' }>
                    <Col span={ 12 }>
                        <>
                            <Heading data-testid='feedback-heading' level={ 1 } style={ { marginTop: '-12px', marginBottom: '32px'} }>
                                Thank you for your feedback!
                            </Heading>
                            <CustomerField
                                label='Please provide any additional feedback here.'
                                helpMessage='Optional'
                                errorMessage={ inputError.comment.errorMessage }
                                hasError={ inputError.comment.hasError }
                                data-testid='feedback-description'>
                                <Textarea
                                    onChange={ handleChange }
                                    data-testid='feedbackCommentArea'
                                />
                            </CustomerField>
                            <div style={
                                {
                                    width: '50%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    columnGap: '24px',
                                    marginTop: '32px'
                                }
                            }>
                                <Button
                                    onClick={ feedbackSend }
                                    variant='primary'
                                    data-testid='submitBtn'>
                                    Submit
                                </Button>
                            </div>
                        </>
                    </Col>
                </Row>
            </SectionPage>
        </ContentContainer>
    );
};

FeedbackComponent.propTypes = {
    onChange: PropTypes.func
};
export default FeedbackComponent;
